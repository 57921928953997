import bossXypq from '../utils/bossXypq'
import bossApi from '../utils/bossApi'
import bossCloud from '../utils/bossCloud'

// 省市区 列表
export function districts() {
  return bossXypq({ url: 'hx.baoxian/common/district', method: 'get' })
}

// 省 列表
export function provinceList() {
  return bossXypq({ url: 'hx.baoxian/common/district/province', method: 'get' })
}

// 阿里oss上传文件policy
export function UploadFileApi() {
  return bossXypq({ url: 'hx.baoxian/common/oss/policy', method: 'GET' })
}

// 微信code换openid
export function GetOpenID(data) {
  return bossXypq({ url: 'hx.baoxian/login/mp/accessToken?code=' + data, method: 'GET' })
}

// 微信JsSDK授权
export function getJSSDKSign(data) {
  return bossXypq({ url: 'hx.baoxian/user/getJSSDKSign?url=' + data, method: 'get' })
}
// 调用支付 -- 商城
export function orderPay(data) {
  return bossXypq({ url: 'hx.baoxian/mallOrder/buyer/mallPay/mp', method: 'POST', data })
}

// 调用支付 -- 云课堂
export function cloudroom_orderPay(data) {
  return bossApi({ url: 'cmb/pay/orderPay', method: 'POST', data })
}
// 营业证识别
export function common_ocr(data) {
  return bossXypq({ url: 'hx.baoxian/common/ocrOms?orcUrl=' + data, method: 'get' })
}
// 发票 - 公司模糊搜索
export function wildcard(data) {
  return bossCloud({ url: 'admin/api/enterprise/wildcard', method: 'POST', data })
}
// 发票 - 企业基本信息查询-部分-term查询
export function enterprise_info(data) {
  return bossCloud({ url: 'admin/api/enterprise/info', method: 'POST', data })
}
