const state = {
  companyId: '',
  companyName: '',
  openid: '',
  industryName: '',
  name: '',
  avatar: '',
  accessToken: '',

  qualification: 0
}

const mutations = {
  SET_COMPANYID: (state, value) => {
    state.companyId = value
  },
  SET_COMPANYNAME: (state, value) => {
    state.companyName = value
  },
  SET_INDUSTRYNAME: (state, value) => {
    state.industryName = value
  },
  SET_NAME: (state, value) => {
    state.name = value
  },
  SET_OPENID: (state, value) => {
    state.openid = value
  },
  SET_AVATAR: (state, value) => {
    state.avatar = value
  },
  SET_ACCESSTOKEN: (state, value) => {
    state.accessToken = value
  },
  SET_QUALIFICATION: (state, value) => {
    state.qualification = value
  }
}

const actions = {
  setCompanyID({ commit }, value) {
    commit('SET_COMPANYID', value)
  },
  setCompanyName({ commit }, value) {
    commit('SET_COMPANYNAME', value)
  },
  setIndustryName({ commit }, value) {
    commit('SET_INDUSTRYNAME', value)
  },
  setName({ commit }, value) {
    commit('SET_NAME', value)
  },
  setOpenID({ commit }, value) {
    commit('SET_OPENID', value)
  },
  setAvatar({ commit }, value) {
    commit('SET_AVATAR', value)
  },
  setAccessToken({ commit }, value) {
    commit('SET_ACCESSTOKEN', value)
  },
  setQualification({ commit }, value) {
    commit('SET_QUALIFICATION', value)
  },

  clear({ commit }) {
    commit('SET_COMPANYID', '')
    commit('SET_INDUSTRYNAME', '')
    commit('SET_NAME', '')
    // commit('SET_OPENID', '')
    commit('SET_AVATAR', '')
    commit('SET_ACCESSTOKEN', '')
    commit('SET_QUALIFICATION', '')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
