import axios from 'axios'

const isNewVersion = async () => {
  var url = `//${window.location.host}/version.json?t=${new Date().getTime()}`
  var res = await axios.get(url)
  if (res.status === 200) {
    var vueVersion = res.data.version
    var localVueVersion = localStorage.getItem('vueVersion')
    if (localVueVersion && localVueVersion !== vueVersion) {
      localStorage.setItem('vueVersion', vueVersion)
      window.location.reload()
    } else {
      localStorage.setItem('vueVersion', vueVersion)
    }
  }
  return res.data.version
}

export default {
  isNewVersion
}
