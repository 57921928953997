import axios from 'axios'
import { Toast } from 'vant'
import router from '../router'
// import store from '@/store'

// create an axios instance
console.log('======', process.env.VUE_APP_BASE_BOSS_API)
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_BOSS_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 6000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (JSON.parse(localStorage.getItem('USER_INFO'))) {
      config.headers['accesstoken'] = JSON.parse(localStorage.getItem('USER_INFO')).accessToken
    } else if (localStorage.getItem('token')) {
      config.headers['accesstoken'] = localStorage.getItem('token')
    }
    config.headers['platform'] = 2
    // config.headers['accesstoken'] = '439f90758925968702d768ad38200962'
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200 && res.code !== 0) {
      Toast.fail({ message: res.msg || 'Error', duration: 2 * 1000 })
      if (res.code === 10002) {
        // Toast({
        //   message: '您身份验证已过期，需要再次登录',
        //   type: 'error',
        //   duration: 3 * 1000,
        //   onClose: function () {
        //     location.reload()
        //   }
        // })
        // location.reload()
        router.replace('/user/login')
        return Promise.reject(new Error(res.msg || 'Error'))
      }
      if (res.code === 10166) { return res }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    Toast.fail({ message: 'Error', duration: 2 * 1000 })
    return Promise.reject(error.msg)
  }
)

export default service
