// 使用store做数据持久化存储 & 过期时间
import store from 'store/dist/store.legacy.js'
import store_expire from 'store/plugins/expire.js'
store.addPlugin(store_expire)
import Vue from 'vue'
import { shoppingCart_productTypes } from '@/api/cart'
/**
 *对Date的扩展，将 Date 转化为指定格式的String
 *月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
 *年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 *例子：
 *(new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 *(new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
 */
// eslint-disable-next-line no-extend-native
Date.prototype.format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    'S': this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[
        k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}

/**
  * @防抖节流函数
*/
let canRun = true
//! !!!这里一定要把变量设在外面，不然会失效！
export function debounce(fn, delay) {
  const context = this // this指向发生变化，需要提出来
  const args = arguments
  return function () {
    if (context.timeout) {
      clearTimeout(context.timeout)
    }
    context.timeout = setTimeout(() => { // 然后又创建一个新的 setTimeout, 这样就能保证输入字符后的 interval 间隔内如果还有字符输入的话，就不会执行 fn 函数
      fn.apply(context, args)
    }, delay)
  }
}
export function throttle(fn) {
  return function () {
    if (!canRun) return
    canRun = false
    setTimeout(function () {
      fn.apply(this, arguments)
      canRun = true
    }, 3000)
  }
}

/**
 * @金额按照小数点截取
*/
export const moneyDote = (val) => {
  var vals = val.toString()
  var beforeM = vals.split('.')[0]
  var afterM = vals.split('.')[1]
  return [beforeM, afterM]
}

/**
 * @登录公用方法使用
 * 确定身份 进入不用页面
*/
export function loginToIndex(val) {

}

// 获取缓存
export const cGet = (key) => store.get(key)

// 删除缓存
export const cDel = (key) => store.remove(key)

// 设置缓存(过期时间)
export const cSet = (key, value, expire_time) => {
  expire_time === undefined ? store.set(key, value) : store.set(key, value, expire_time)
}
// 时间处理函数(前端)
export const getToday = () => {
  const date = new Date()
  date.setDate(date.getDate() + 1)
  return date.format('yyyy-MM-dd')
}
// 跳转到客服
export const goKf = () => {
  window.location.href = 'https://work.weixin.qq.com/kfid/kfc396ef261a54ab364'
}

// 自动删除用户信息
export const storeUserInfoClear = async () => {
  // 删除缓存
  cDel('USER_INFO')
  await Vue.prototype.$store.dispatch('user/clear')
  await Vue.prototype.$store.dispatch('shopping/clear')
}

// 自动注入用户信息
export const storeUserInfo = (store, info) => {
  console.log('info', info)
  // 设置应用store
  store.dispatch('user/setOpenID', info.openid)
  store.dispatch('user/setAccessToken', info.accessToken)
  store.dispatch('user/setName', info.name)
  store.dispatch('user/setAvatar', info.avatar)
  store.dispatch('user/setCompanyID', info.companyId)
  store.dispatch('user/setCompanyName', info.companyName)
  store.dispatch('user/setIndustryName', info.industryName)
}
// 登录使用公用方法
// 统一处理用户信息本地存储
export const localLogin = (vm, info, type) => {
  // 设置应用store
  storeUserInfo(vm.$store, info)
  // 持久化存储本地
  cSet('USER_INFO', info)
  localStorage.setItem('token', info.accessToken)
  var flag_shop = false // 是否有优选商城
  var flag_wholesale = false // 是否有宠商首选
  var flag_szxq = false // 是否有四爪星球
  for (const i in info.categoryId) {
    if (info.categoryId[i] === '1') { flag_shop = true }
    if (info.categoryId[i] === '8') { flag_wholesale = true }
    if (info.categoryId[i] === '9') { flag_szxq = true }
  }
  if (type === 'login_pet') {
    // 宠物医院登录
    if (flag_shop) {
      if (info.certificationType !== 1) {
        vm.$router.replace('/home/preCertification')
      } else {
        if (sessionStorage.getItem('shareUrl')) { vm.$router.replace(sessionStorage.getItem('shareUrl')) } else { vm.$router.replace('/home/index') }
      }
    } else if (flag_szxq) {
      cDel('USER_INFO')
      localStorage.clear()
      console.log('USER_INFO', JSON.stringify(info))
      localStorage.setItem('token', info.accessToken)
      vm.$router.replace('/user/szxq_info')
    } else if (flag_wholesale) {
      vm.$router.replace('/user/error')
    } else {
      vm.$router.replace('/user/error')
    }
  }
  if (type === 'login_vip') { vm.$router.replace('/user/my') }
  sessionStorage.clear()
}
// 统一退出
export const localLogout = async (vm) => {
  // 删除缓存
  cDel('USER_INFO')
  await vm.$store.dispatch('user/clear')
  await vm.$store.dispatch('shopping/clear')
  // var openid = localStorage.getItem('openId')
  localStorage.clear()
  sessionStorage.clear()
  vm.$toast({ message: '退出成功！', duration: 2 * 1000 })
  vm.$router.replace({ path: '/user/login' })
  // localStorage.setItem('openId', openid)
  window.location.href = process.env.VUE_APP_BASE_HOST
}

// 合并数组
const contactList = (list_1, list_2) => {
  list_1 = list_1 || []
  list_2 = list_2 || []
  return [...list_1, ...list_2]
}
// 初始化区域选择
export const initZoneIndex = (zoneList, provinceId, cityId, areaId) => {
  const district_index = []
  const district = []

  for (const index_1 in zoneList) {
    const zone_1 = zoneList[index_1]
    if (zone_1.value !== provinceId) {
      continue
    }

    // 加入地区
    district.push(zone_1.label)
    district_index.push(index_1)

    for (const index_2 in zone_1.children) {
      const zone_2 = zone_1.children[index_2]
      if (zone_2.value !== cityId) {
        continue
      }

      // 加入城市
      district.push(zone_2.label)
      district_index.push(index_2)

      for (const index_3 in zone_2.children) {
        const zone_3 = zone_2.children[index_3]
        if (zone_3.value !== areaId) {
          continue
        }

        // 加入区域
        district.push(zone_3.label)
        district_index.push(index_3)
        break
      }
      break
    }
    break
  }

  // 合法数据
  if (district_index.length === 3) {
    return {
      district: district.join('-'),
      district_index
    }
  } else {
    return {
      district: '',
      district_index: [0, 0, 0]
    }
  }
}
// 公用路径方法 -1:后退+刷新； 0:刷新； 1:前进；
export const pathGoBack = (path) => {
  window.history.back(path)
}

// 获取购物车种类
export const cartNum = (store) => {
  shoppingCart_productTypes().then(res => { store.dispatch('shopping/setTotal', parseInt(res.data)) })
}
// 根据秒数转换成对应的时分秒
export function getHMS(time) {
  const hour = parseInt(time / 3600) < 10 ? '0' + parseInt(time / 3600) : parseInt(time / 3600)
  const min = parseInt(time % 3600 / 60) < 10 ? '0' + parseInt(time % 3600 / 60) : parseInt(time % 3600 / 60)
  const sec = parseInt(time % 3600 % 60) < 10 ? '0' + parseInt(time % 3600 % 60) : parseInt(time % 3600 % 60)
  return hour + ':' + min + ':' + sec
}
export default {
  debounce: debounce,
  moneyDote: moneyDote,
  cGet: cGet,
  cDel: cDel,
  cSet: cSet,
  goKf: goKf,
  storeUserInfo: storeUserInfo,
  storeUserInfoClear: storeUserInfoClear,
  localLogin: localLogin,
  localLogout: localLogout,
  contactList: contactList,
  getToday: getToday,
  initZoneIndex: initZoneIndex,
  pathGoBack: pathGoBack,
  cartNum: cartNum,
  getHMS: getHMS
}
