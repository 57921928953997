const getters = {
  // 购物车商品数量
  totalShopping: state => state.shopping.total,

  industryName: state => state.user.industryName,
  accessToken: state => state.user.accessToken,
  name: state => state.user.name,
  openid: state => state.user.openid,
  avatar: state => state.user.avatar,
  companyId: state => state.user.companyId,
  companyName: state => state.user.companyName,
  qualification: state => state.user.qualification
}
export default getters
