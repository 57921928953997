const state = {
  total: 0 // 购物车商品数量
}

const mutations = {
  SET_TOTAL: (state, value) => {
    state.total = value
  }
}

const actions = {
  setTotal({ commit }, value) {
    commit('SET_TOTAL', value)
  },
  clear({ commit }) {
    commit('SET_TOTAL', 0)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
