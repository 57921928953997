// lbc.js
// import Vue from 'vue'

const api = process.env.VUE_APP_BASE_BOSS_XYPQ
const host = process.env.VUE_APP_BASE_HOST// 授权所需域名
const appId = process.env.VUE_APP_BASE_APPID

// 获取地址栏参数
const getQueryString = (name) => {
  // eslint-disable-next-line no-sparse-arrays
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
}

export default {
  getQueryString,
  api,
  host,
  appId
}
