<template>
  <div id="app">
    <!-- <router-view :key="$route.fullPath" /> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath" include="list" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" />
  </div>
</template>
<script>
import '../src/app.scss'
// import Vue from 'vue'
export default {
  name: 'App',
  data() { return {} },
  mounted() {
    // 检测浏览器路由改变页面不刷新问题,hash模式的工作原理是 hashchange事件
    window.addEventListener('hashchange', () => {
      const currentPath = window.location.hash.slice(1)
      if (this.$route.path !== currentPath) {
        this.$router.push(currentPath)
      }
    }, false)

    // 挂载成功后给pop事件绑定一个方法
    // 如果支持 popstate （一般移动端都支持）
    if (window.history && window.history.pushState) {
      // 往历史记录里面添加一条新的当前页面的url
      history.pushState(null, null, document.URL)
      // 给 popstate 绑定一个方法用来监听页面返回
      window.addEventListener('popstate', this.back, false) // false阻止默认事件
    }
  },
  // 组件销毁后清除事件
  destroyed() {
    window.removeEventListener('popstate', this.back(), false) // false阻止默认事件
  },
  onLaunch: function () {
    // 启动自动从本地存储注入到 store
    this.$utils.localInjectToStore()
  },
  methods: {
    back() {
      console.log('监听到了')
    }
  }
}
</script>
<style lang="stylus">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
