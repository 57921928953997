/**
 * @用户模块
 * 2022-07-29 kadiam
*/
import bossApi from '../utils/bossApi'
import bossXypq from '../utils/bossXypq'
import bossCloud from '../utils/bossCloud'

// openid 快速登录
export function login_quick(data) {
  return bossXypq({ url: 'hx.baoxian/login/quick?openId=' + data + '&platformType=wxmp', method: 'GET' })
}
// ssoToken 快速登录
export function login_quick_sso() {
  return bossCloud({ url: 'sso/api/login/loginBySsoToken', method: 'GET'})
}
// 发送短信验证码
export function sms_send(data) {
  return bossCloud({ url: 'sso/api/mobile/sysxSendsms', method: 'post', data })
}
// 修改手机号检查
export function modifyMobileCheck(data) {
  return bossXypq({ url: 'hx.baoxian/user/modifyMobileCheck', method: 'post', data })
}
// 修改手机号
export function modifyMobile(data) {
  return bossXypq({ url: 'hx.baoxian/user/modifyMobile', method: 'post', data })
}
// 用户登录接口
export function login_mobile(data) {
  return bossXypq({ url: 'hx.baoxian/login/mobile', method: 'post', data })
}

// 补充修改用户信息 szxq
export function userInfo_update(data) {
  return bossCloud({ url: 'sso/api/userInfo/update', method: 'POST', data })
}
// 用户注册接口
export function mobile_register3(data) {
  return bossXypq({ url: 'hx.baoxian/mobile/register3', method: 'post', data })
}
// 用户退出
export function logOut(data) {
  return bossXypq({ url: 'hx.baoxian/logOut', method: 'get' })
}
// 用户退出 2
export function kickOutByToken() {
  return bossCloud({ url: 'sso/api/kickOut/kickOutByToken', method: 'get' })
}
// 用户认证判断
export function user_certificationDecide() {
  return bossXypq({ url: 'hx.baoxian/user/certificationDecide', method: 'get' })
}

// 提交工商信息认证资料(宠医)
export function user_addGongShangInfo(data) {
  return bossXypq({ url: 'hx.baoxian/user/addGongShangInfo', method: 'post', data })
}

// 获取用户信息 废弃
export function user_detail(data) {
  return bossXypq({ url: 'hx.baoxian/user/detail', method: 'GET' })
}
// 获取用户信息
export function user_userInfo(data) {
  return bossCloud({ url: 'sso/api/userInfo/info', method: 'GET' })
}
// 更新用户信息
export function user_update(data) {
  return bossXypq({ url: 'hx.baoxian/user/update', method: 'post', data })
}

// 我的优惠券列表
export function user_coupons(data) {
  return bossXypq({ url: 'hx.baoxian/user/coupons', method: 'post', data })
}
// 合并优惠券
export function mergeCoupon(data) {
  return bossXypq({ url: 'hx.baoxian/couponUser/mergeCoupon', method: 'post', data })
}
// 收货地址列表
export function userReceiptAddress_list(data) {
  return bossXypq({ url: 'hx.baoxian/userReceiptAddress/list', method: 'post', data })
}
// 收货地址列表 专题活动
// export function userReceiptAddressList(data) {
//   return bossXypq({ url: 'hx.baoxian/mallPreferential/userReceiptAddressList', method: 'post', data })
// }
export function userReceiptAddressList(data) {
  return bossCloud({ url: 'mall/api/mallPreferential/userReceiptAddressList', method: 'post', data })
}

// 收货地址详情
export function userReceiptAddress_getaddress(data) {
  return bossXypq({ url: 'hx.baoxian/userReceiptAddress/getaddress?userReceiptAddressId=' + data, method: 'GET' })
}

// 收货地址删除
export function userReceiptAddress_del(data) {
  return bossXypq({ url: 'hx.baoxian/userReceiptAddress/del?userReceiptAddressId=' + data, method: 'GET' })
}

// 收货地址（新增与修改）
export function userReceiptAddress_addAndUpdate(data) {
  return bossXypq({ url: 'hx.baoxian/userReceiptAddress/addAndUpdate', method: 'post', data })
}
// 用户收藏-商品列表
// export function collection_list(data) {
//   return bossXypq({ url: 'hx.baoxian/user/collection/list', method: 'post', data })
// }
export function collection_list(data) {
  return bossCloud({ url: 'mall/api/userCollection/list', method: 'post', data })
}
// 用户中心-等待付款
export function user_rePay() {
  return bossXypq({ url: 'hx.baoxian/user/center/order/rePay', method: 'GET' })
}
// 已邀请列表
export function user_invites(data) {
  return bossXypq({ url: 'hx.baoxian/user/invites', method: 'post', data })
}
// 邀请code
export function user_inviteCode(data) {
  return bossXypq({ url: 'hx.baoxian/user/inviteCode', method: 'get' })
}
// 用户中心-订单统计
export function user_order_statistics(data) {
  return bossXypq({ url: 'hx.baoxian/user/center/order/statistics', method: 'get' })
}

// 收货地址（地址解析
export function parseAddress(data) {
  return bossApi({ url: 'addressSmartParse/parseAddress?address=' + data, method: 'GET' })
}

// 认证+注册--->获取法人信息
export function enterpriseInformation(data) {
  return bossXypq({ url: 'hx.baoxian/authOcr/enterpriseInformation', method: 'POST', data })
}
// 认证+注册--->手机号二要素验证
export function mobileIsReal(data) {
  return bossXypq({ url: 'hx.baoxian/authOcr/mobileIsReal', method: 'POST', data })
}
// 认证+注册--->手机号二要素验证
export function authAndReg(data) {
  return bossXypq({ url: 'hx.baoxian/authOcr/authAndReg', method: 'POST', data })
}
