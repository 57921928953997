import { getJSSDKSign } from '@/api/public'

const jweixin = require('jweixin-module')

export default {
  // 初始化sdk配置
  initJssdk: function (callback, current_url) {
    // 服务端进行签名 ，可使用uni.request替换。 签名算法请看文档
    getJSSDKSign(current_url).then(res => {
      var info = res.data
      const data = {
        debug: false,
        appId: info.appId,
        timestamp: info.timestamp,
        nonceStr: info.nonceStr,
        signature: info.signature
        // jsApiList: [
        //   'checkJsApi',
        //   'onMenuShareAppMessage',
        //   'onMenuShareTimeline',
        //   'updateAppMessageShareData'
        // ]
      }
      data['jsApiList'] = ['checkJsApi', 'onMenuShareAppMessage', 'onMenuShareTimeline', 'updateAppMessageShareData']
      console.log('initJssdk::', info, data)
      jweixin.config(data)
      // 配置完成后，再执行分享等功能
      if (callback) {
        callback(info)
      }
    })
  },
  // 在需要自定义分享的页面中调用
  share: function (data, url) {
    const localUrl = location.href.split('#')[0]
    url = encodeURIComponent(localUrl)
    var urlLink = location.href
    console.log('share sheare url  url :', url)
    console.log('share sheare data :', data)
    // 每次都需要重新初始化配置，才可以进行分享
    this.initJssdk(function (signData) {
      console.log('share sheare signData :', signData)
      jweixin.ready(function () {
        var shareData = {
          title: data.title,
          desc: data.desc,
          link: urlLink,
          imgUrl: data.img,
          success: function (res) {
            console.log('initJssdk success', res)
            console.log('initJssdk success:', urlLink)
            // 用户点击分享后的回调，这里可以进行统计，例如分享送金币之类的
            // request.post('/api/member/share');
          },
          cancel: function (res) {
            console.log('initJssdk error:', res)
          }
        }
        console.log('jweixin shareData', shareData)
        // 分享给朋友接口
        jweixin.onMenuShareAppMessage(shareData, function (res) {
          console.log('onMenuShareAppMessage success', res)
        }, function (res) {
          console.log('onMenuShareAppMessage fail', res)
        })
        // 分享到朋友圈接口
        jweixin.onMenuShareTimeline(shareData)
        jweixin.updateAppMessageShareData(shareData, function (res) {
          console.log('updateAppMessageShareData success', res)
        }, function (res) {
          console.log('updateAppMessageShareData fail', res)
        })
      })
    }, url)
  },

  // 发票信息
  invoice: function (data, url) {
    const localUrl = location.href.split('#')[0]
    url = encodeURIComponent(localUrl)
    this.initJssdk(function (signData) {
      console.log('invoice invoice signData :', signData)
      jweixin.ready(function () {
        jweixin.invoke('chooseInvoiceTitle'
          , { 'scene': '1' }
          , function (res) {
            console.log(res)
            var info = res.choose_invoice_title_info
            if (info != null && info !== undefined) {
            // { "type":"0", "title":"腾讯科技(深圳)有限公司", "taxNumber":"123466789987646131", "companyAddress":"深圳市南山区某某路腾讯大厦", "telephone":"123456789", "bankName":"某某银行", "bankAccount":"621111111111290" }
              console.log(info)
            }
          })
      })
    }, url)
  }

}
